import React, { useEffect, useState } from "react";
import { SinglePostWrapper } from "./_singlePostStyle";
import { Col, Row, Container } from "reactstrap";
import SocialShare from "../../../components/social-share/SocialShare";
import { FileText } from "react-feather";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useAuth0 } from "@auth0/auth0-react";

import axios from "axios";
import parse from "html-react-parser";
import { useParams, Link, useHistory } from "react-router-dom";

const SinglePost = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const [userMetadata, setUserMetadata] = useState(null);

  // const postLayout = "rightSidebar";
  // fullWidth;
  // rightSidebar;
  // leftSidebar

  const domain = "login.mycoa.io";

  const { slug } = useParams();
  const baseURL = "https://mycoa.io/api";
  const [post, setPost] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/page-template/singlePage/${slug}`).then((response) => {
      //setPost(response.data.data);
      if( response.data.data.short_description==null){
      response.data.data.short_description = response.data.data.body.substring(0, 500)+' ...'
      }else{response.data.data.short_description = response.data.data.short_description+' ...'}
      setPost(response.data.data);
    });
  }, []);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata().then(function (result) {
       console.info("result", result);
      //  document.querySelector("#authCan").classList.add("hide");
      //  document.querySelector("#noAuthCan").classList.add("hide");
      //if (isAuthenticated && result && result.CPAN == "CPAN") {
        if (isAuthenticated && result && result.initiative.split(",").indexOf("CPAN") !== -1) {
        document.querySelector("#authCan").classList.remove("hide");
        document.querySelector("#noAuthCan").classList.add("hide");
      } else {
        document.querySelector("#authCan").classList.add("hide");
        document.querySelector("#noAuthCan").classList.remove("hide");
      }
    });
  }, [getAccessTokenSilently, user?.sub]);

  return post && post != undefined && post != "" ? (
    <>
      <SinglePostWrapper>
        <Container>
          <Row className={`layout-wrapper ${post.name}`}>
            <Col className="col-auto d-none d-lg-block social-share">
              <SocialShare />
            </Col>
            <Col className="content col-auto">
              {post && post !== undefined ? (
                <article>
                  <div className="post-wrapper">
                    <div className="post-header">
                      <h2 className="post-title">{post.title}</h2>
                      <span className="meta-date">{post.created_at}</span>
                      <div className="d-block d-lg-none">
                        <SocialShare mobile="mobile" />
                      </div>
                    </div>
                    <div className="post-content">
                      {isAuthenticated ? (
                        <div>
                          {parse(`${post.body}`)}
                          {/* <div id="noAuthCan">
                            {parse(`${post.short_description}`)}
                          </div>
                          <div id="authCan" className="hide">
                            {parse(`${post.body}`)}
                          </div> */}
                        </div>
                      ) : (
                        <div id="noAuthCan">
                           {parse(`${post.short_description}`)}
                        </div>
                      )}
                    </div>
                  </div>
                </article>
              ) : (
                <p>...</p>
              )}
            </Col>

            <Col className="sidebar col-auto">
              <div className="post-sidebar">
                <a href="#" target="blank" className="download-button">
                  <i className="fa-solid fa-file-pdf"></i>
                  <span className="button-text d-flex align-items-center justify-content-center">
                    <FileText size={16} className="mr-25" /> DOWNLOAD (PDF)
                  </span>
                </a>

                <div className="post-img">
                  <img
                    src="https://communityoncology.org/wp-content/uploads/2020/04/IMG_2175.jpg"
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div className="related-posts-wraper">
                  <h4>More Related Content</h4>
                  <div className="related-posts">
                    <h2>Related Posts</h2>
                    <ul className="related-posts-list">
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          Aptent leo tristique velit vulputate eleifend
                        </a>
                      </li>
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          Aptent leo tristique velit vulputate eleifend
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SinglePostWrapper>
      <PostTeaser />
    </>
  ) : (
    <p>...</p>
  );
};

export default SinglePost;
