import React, { useEffect, useState } from "react";
import { SinglePostWrapper } from "./_singlePostStyle";
import { Col, Row, Container } from "reactstrap";
import SocialShare from "../../../components/social-share/SocialShare";
import { FileText } from "react-feather";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useAuth0 } from "@auth0/auth0-react";
import articleImage from "../../../assets/images/article-image.png";

const SinglePost = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const [userMetadata, setUserMetadata] = useState(null);
  const domain = "login.mycoa.io";
  // const getUserMetadata = async () => {
  //   try {
  //     const accessToken1 = await getAccessTokenSilently({
  //       audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
  //       scope: "read:current_user"
  //     });

  //     const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  //     const metadataResponse = await fetch(userDetailsByIdUrl, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken1}`,
  //       },
  //     });

  //     const { user_metadata } = await metadataResponse.json();
  //     console.info("user_metadata", user_metadata)
  //     setUserMetadata(user_metadata);
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  // };

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata().then(function (result) {
      //if (isAuthenticated && result && result.CPAN === "CPAN") {
      if (
        isAuthenticated &&
        result &&
        result.initiative.split(",").indexOf("CPAN") !== -1
      ) {
        document.querySelector("#authCan").classList.remove("hide");
        document.querySelector("#noAuthCan").classList.add("hide");
      } else {
        document.querySelector("#authCan").classList.add("hide");
        document.querySelector("#noAuthCan").classList.remove("hide");
      }
    });
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <>
      <SinglePostWrapper>
        <Container>
          <Row>
            <Col className="col-auto d-none d-lg-block">
              <SocialShare />
            </Col>
            <Col lg="8" md="8">
              <article>
                <div className="post-wrapper">
                  <div className="post-header">
                    <h2 className="post-title">
                      COA Comments to Congress on MACRA RFI
                    </h2>
                    <span
                      className="meta-date"
                      style={{ color: "var(--primary)", fontWeight: "800" }}
                    >
                      November 1, 2022
                    </span>
                    <div className="d-block d-lg-none">
                      <SocialShare mobile="mobile" />
                    </div>
                  </div>
                  <div className="post-content">
                    <Row className="align-items-center mb-3">
                      <Col lg="6">
                        <img src={articleImage} alt="image" className="w-100" />
                      </Col>
                      <Col lg="6">
                        <small className="text-primary">
                          1225 New York Ave. NW, Suite 600 Washington, DC 20005
                          (202) 729-8147 | communityoncology.org
                        </small>
                      </Col>
                    </Row>
                    <p>October 31, 2022</p>
                    <p>
                      <em>Submitted via email to: </em>
                      <a href="mailto:macra.rfi@mail.house.gov">
                        <em>macra.rfi@mail.house.gov</em>
                      </a>
                    </p>
                    <p>
                      The Honorable Ami Bera
                      <br />
                      The Honorable Larry Bucshon
                      <br />
                      The Honorable Kim Schrier
                      <br />
                      The Honorable Michael Burgess
                      <br />
                      The Honorable Earl Blumenauer
                      <br />
                      The Honorable Brad Wenstrup
                      <br />
                      The Honorable Brad Schneider
                      <br />
                      The Honorable Mariannette Miller-Meeks
                      <br />
                      United States House of Representatives
                      <br />
                      Washington, D.C. 20515
                    </p>
                    <p>
                      Re:{" "}
                      <em>
                        Medicare Access and CHIP Reauthorization Act (MACRA)
                        Request for Information{" "}
                      </em>
                      (“RFI”)
                    </p>
                    <p>
                      Dear Representatives: Bera, Bucshon, Schrier, Burgess,
                      Blumenauer, Wenstrup, Schneider, and Miller-Meeks:
                    </p>
                    <p>
                      On behalf of the Board of Directors of the Community
                      Oncology Alliance (“COA”), we are submitting this response
                      to the House of Representatives MACRA RFI seeking input on
                      stabilizing the Medicare payment system.
                    </p>
                    <p>
                      As you know, COA is an organization dedicated to
                      advocating for the complex care and access needs of
                      patients with cancer and the community oncology practices
                      that serve them.&nbsp; COA is the only non-profit
                      organization in the United States dedicated solely to
                      independent community oncology practices, which serve the
                      majority of Americans receiving treatment for
                      cancer.&nbsp; Since its grassroots founding close to 20
                      years ago, COA’s mission has been to ensure that patients
                      with cancer receive quality, affordable, and accessible
                      cancer care in their own communities where they live and
                      work, regardless of their racial, ethnic, demographic, or
                      socioeconomic status.
                    </p>
                    {/* {isAuthenticated ? ( */}
                    <div id="authCan" className="hide">
                      <div>
                        <p>
                          <strong>
                            COA is a recognized leader and staunch supporter of
                            value-based care initiatives, which we believe can
                            lower costs and increase quality for patients.
                          </strong>
                          &nbsp; This is evidenced through community oncology
                          practices’ 35 value-based care arrangements with
                          commercial payers including Aetna, UnitedHealthcare,
                          and numerous Blue Cross Blue Shield Association plans
                          across the country, as well as Medicare.
                          <a href="#_ftn1" name="_ftnref1">
                            [1]
                          </a>
                          &nbsp; COA is also committed to partnering with
                          commercial payers and employers who work with the
                          Center for Medicare &amp; Medicaid Innovation (“CMMI”)
                          on value-based care models that are specific to
                          oncology.
                        </p>
                        <p>
                          <strong>
                            When considering how to improve MACRA, we encourage
                            Congress to take action to create true payment
                            parity across community oncology practices and
                            health systems furnishing oncology services in order
                            to improve care and reduce costs in the
                            system.&nbsp;{" "}
                          </strong>
                          The “siloed” Medicare system of outpatient hospital
                          and physician office payment systems is not only
                          antiquated, but it has drastically increased costs for
                          Medicare.&nbsp; Rather than incentivize and reward
                          value in terms of high-quality, cost-effective medical
                          care, the Centers for Medicare &amp; Medicaid Services
                          (“CMS”) has done just the opposite, grossly overpaying
                          hospital outpatient departments, including their
                          off-campus outpatient facilities.&nbsp; Health
                          systems, especially those with 340B drug discounts,
                          have been given a free license to overcharge Medicare,
                          commercial, and uninsured payers and patients.
                          <a href="#_ftn2">[2]</a>
                        </p>
                        <p>
                          While COA appreciates the stated intent of CMS and
                          CMMI of promoting value-based healthcare (“VBHC”)
                          through recent payment models, such as the new
                          Enhancing Oncology Model (“EOM”), current Medicare
                          alternative payment and quality initiatives do not
                          allow independent community oncology practices to meet
                          the required risk thresholds to exempt them from the
                          Merit-Based Incentive Payment System (“MIPS”).&nbsp;
                          Reporting requirements under alternative payment
                          models (“APMs”) place significant burdens on community
                          oncology practices, frequently leaving them without
                          adequate resources and support needed to implement
                          practice transformation activities.&nbsp;{" "}
                          <strong>
                            As Congress considers ways to improve MACRA, it
                            should pay particular attention to the financial and
                            operational burdens placed on physician
                            practices.&nbsp; It should also consider ways to
                            reduce and/or remove barriers to encourage the
                            adoption of VBHC approaches.&nbsp;{" "}
                          </strong>
                        </p>
                        <p>
                          <strong>
                            COA is focusing its comments on the following areas:
                          </strong>
                        </p>
                        <ul>
                          <li>
                            <strong>Ineffectiveness of MACRA</strong>
                          </li>
                          <li>
                            <strong>
                              Increasing Provider Participation in Value-Based
                              Payment Models{" "}
                            </strong>
                          </li>
                          <li>
                            <strong>
                              Recommendations to Improve MIPS and APM Programs
                            </strong>
                          </li>
                          <li>
                            <strong>
                              Unrealistic and Harmful Medicare Reimbursement
                            </strong>
                          </li>
                        </ul>
                        <p>
                          <strong>
                            <u>Ineffectiveness of MACRA</u>
                          </strong>
                        </p>
                        <p>
                          <strong>
                            Impact of MACRA on Community Oncology Practices
                          </strong>
                        </p>
                        <p>
                          COA understands and appreciates Congress’ intent in
                          passing MACRA to speed the transition to VBHC,
                          especially for complex care delivered in community
                          oncology programs.&nbsp;{" "}
                          <strong>
                            However, we believe that MACRA is ineffective and
                            does not achieve its purpose of advancing options
                            for practices participating in VBHC.
                          </strong>
                          &nbsp; While the Sustainable Growth Rate (“SGR”), the
                          payment formula MACRA replaced, was a flawed payment
                          method, MACRA is overly complex and burdensome in ways
                          that disproportionately impact smaller and rural
                          providers, including community oncology practices.
                          <strong>
                            &nbsp; As Congress considers ways to improve MACRA,
                            it should focus on payment methodologies and
                            programs to support physician practices more
                            effectively, such as independent community oncology
                            practices that are documented as high-quality,
                            cost-effective providers.
                          </strong>
                        </p>
                        <p>
                          CMS’ current prospective payment systems also
                          incentivize hospital care to the detriment of
                          community oncology practices, despite the fact that
                          hospital-based cancer care is substantially more
                          expensive and lower-quality.&nbsp; A study conducted
                          using pharmacy and medical claims from 2010-2015
                          showed that hospital-based cancer care for patients
                          undergoing chemotherapy was 60 percent or $90,144
                          higher than care provided in community oncology
                          practices.
                          <a href="#_ftn3" name="_ftnref3">
                            [3]
                          </a>
                          &nbsp; Additionally, the positive payment adjustments
                          in the CY 2023 Outpatient Prospective Payment System
                          (“OPPS”) proposed rule and the payment cuts in the CY
                          2023 Medicare Physician Fee Schedule (“MPFS”) proposed
                          rule further clearly documents how CMS incentivizes
                          hospital care to the detriment of lower cost, but
                          high-quality, independent physician-based medical
                          care.&nbsp; Current Procedural Terminology (“CPT”)
                          codes 96415 and 96417 (add-on codes used to describe
                          IV chemotherapy administration) are respectively 59
                          percent and eight percent higher in hospital
                          outpatient settings than in community oncology
                          practices.
                          <a href="#_ftn4" name="_ftnref4">
                            [4]
                          </a>
                          &nbsp;{" "}
                          <strong>
                            To improve MACRA and promote its mission of
                            encouraging VBHC and increasing high-value paths to
                            care, Congress should take action to incentivize
                            high-quality, cost-effective care by aligning the
                            reimbursement rates of hospital outpatient sites
                            with those of community oncology practices.
                          </strong>
                        </p>
                        <p>
                          While Congress requires CMS to be an effective steward
                          of Medicare funds, the recent year-over-year MPFS
                          payment cuts run counter-productive to that
                          goal.&nbsp; In fact, the financial and operational
                          burdens caused by MACRA have added to a number of
                          other policy decisions and market trends that
                          encourage the assimilation or purchase of smaller
                          practices by larger organizations.
                          <a href="#_ftn5" name="_ftnref5">
                            [5]
                          </a>
                          &nbsp; For example, from 2008 to 2020, 1,748 community
                          oncology clinics and/or practices were acquired by
                          health systems, merged with other investment entities,
                          closed, or reported that they are struggling
                          financially.
                          <a href="#_ftn6" name="_ftnref6">
                            [6]
                          </a>
                          &nbsp; As community oncology reimbursement has been
                          continually ratcheted down over the last two decades,
                          the chemotherapy services delivered by independent
                          community oncology practices declined by 64.3 percent,
                          presenting serious access concerns for cancer
                          patients.
                          <a href="#_ftn7" name="_ftnref7">
                            [7]
                          </a>
                          &nbsp; Payment cuts experienced at community oncology
                          clinics cause them to be acquired by health systems,
                          which disincentives the true goal of VBHC programs
                          intended through MACRA.&nbsp;{" "}
                          <strong>
                            Therefore, Congress should improve MACRA to prevent
                            the further swallowing up of community oncology
                            practices by health systems and stop driving
                            patients to the most expensive sites of care with
                            similar or lesser quality.&nbsp; Congress can
                            improve MACRA by amending MIPS to increase the
                            number of MIPS Value Pathways (“MVPs”),
                            &nbsp;improve scoring, adopt value-based care models
                            specific to medical oncology, and retaining the five
                            percent APM Incentive Payment.
                          </strong>
                        </p>
                        <p>
                          <strong>
                            <u>
                              Increasing Provider Participation in Value-Based
                              Payment Models
                            </u>
                          </strong>
                        </p>
                        <p>
                          <strong>Merit-Based Incentive Payment System</strong>
                        </p>
                        <p>
                          COA supports the purpose of MIPS in promoting VBHC,
                          which was intended to lower costs and improve health
                          outcomes for patients across the country.&nbsp;
                          However, as currently constructed and implemented, the
                          program creates operational and financial burdens and
                          disadvantages participating community oncology
                          practices.&nbsp; MIPS was initially intended to be the
                          training ground for practices to build the skills
                          necessary to take on financial risk in value-based
                          payment models, while the Advance Alternative Payment
                          Model (“AAPM”) track provided a lump-sum bonus to
                          practices participating in two-sided risk models so
                          that they could invest in scaling up the percentage of
                          their revenue flowing through such models.&nbsp;
                          However, the hundreds of quality measures to choose
                          from and extensive flexibilities for reporting offered
                          in the program’s first years meant that savvy
                          organizations could be selective about the elements
                          they report on and be successful under MIPS without
                          improving quality in a meaningful way for
                          patients.&nbsp; MIPS is also overwhelmingly complex
                          (particularly for community oncology practices) and
                          favors practices that are able to navigate the law’s
                          many provisions.&nbsp; Additionally, MIPS’
                          participation options, scoring results, and quality
                          measure options make it difficult for community
                          oncology practices to determine a clear strategy to
                          transform their practices while working to navigate
                          MIPS’ numerous program requirements.&nbsp;{" "}
                          <strong>
                            As Congress considers ways to improve MACRA, it
                            should consider reforms to ensure that MIPS
                            appropriately incentivizes the practice
                            transformation activities that will result in better
                            care at a lower cost.
                          </strong>
                        </p>
                        <p>
                          To facilitate practice transformation and promote the
                          adoption of VBHC, Congress should encourage CMS to
                          improve the use of MVPs.&nbsp; For example, Congress
                          and CMS should work together to ensure that there are
                          a sufficient number of MVPs to cover all
                          specialties.&nbsp; Additionally, Congress and CMS
                          should amend the MVP scoring methodologies to ensure
                          that they do not advantage one MVP over another due to
                          historical benchmarks of the measures included in each
                          one.&nbsp; Doing so would ensure parity between
                          different MVPs.&nbsp; While COA applauds CMS for
                          proposing the new Advancing Cancer Care MVP for CY
                          2023, we recommend that Congress and CMS increase the
                          number of MVPs and address scoring methodology issues
                          while CMS and CMMI create more APMs suitable for
                          community oncology practices.
                        </p>
                        <p>
                          Community oncology practices also face difficulties
                          associated with a lack of APM options relevant to
                          community oncology, which leaves providers no
                          alternatives except participation in MIPS, where the
                          upside is very low, but the requirements are extremely
                          complicated, onerous, and expensive.&nbsp; To counter
                          this situation, CMS should provide greater incentives
                          for participating in APMs even if providers are unable
                          to hit the qualified professionals (“QP”) thresholds
                          as they wait for more oncology-focused APMs to become
                          available so they can meet the thresholds of the AAPM
                          track.
                          <strong>
                            &nbsp; To that end, Congress should encourage CMS to
                            provide greater incentives to practices for
                            attaining MIPS APM status.
                          </strong>
                        </p>
                        <p>
                          <strong>
                            Value-Based Care Models Specific to Medical Oncology
                          </strong>
                        </p>
                        <p>
                          As Congress considers how to promote VBHC, it should
                          ensure that community oncology providers have had
                          sufficient opportunities to participate in medical
                          oncology-focused payment models, which should inform
                          any VBHC/MACRA reform.&nbsp; Many community oncology
                          providers find the reporting and care transformation
                          requirements of the Medicare Oncology Care Model
                          (“OCM”) overly burdensome, leaving them leery of
                          participating in its successor, the EOM.&nbsp; The EOM
                          also only includes seven cancer types, making it
                          highly unlikely that practices would hit the required
                          volume thresholds to obtain QP status under this model
                          alone.&nbsp; With EOM as the only AAPM option for most
                          community oncology providers, they are effectively
                          shut out of the AAPM track.&nbsp; Though there are two
                          tracks for risk arrangement under the EOM, only one of
                          these tracks qualifies as an AAPM, meaning EOM
                          participants that do not choose to participate under
                          the more aggressive risk arrangement would be forced
                          to also assume MIPS reporting requirements in addition
                          to what is required for EOM participation.&nbsp; This
                          leaves practices choosing to participate in EOM with
                          the option to either enter into an aggressive,
                          immediate downside risk arrangement with little to no
                          exposure to VBHC or increase their reporting
                          requirements through adherence to both MIPS and EOM
                          quality/payment methodology.&nbsp; While this is
                          onerous for all practices involved, community oncology
                          practices will be significantly hindered in their
                          performance due to a lack of resources, continued
                          threat of payment cuts, and consolidation.
                          <strong>
                            &nbsp; COA encourages Congress to work with CMS to
                            provide more options for models available to
                            community oncologists and prevent VBHC initiatives
                            from backsliding, which occurred as a result of the
                            unreasonably large 12-month gap between the OCM’s
                            termination and the EOM’s commencement.
                          </strong>
                        </p>
                        <p>
                          While COA believes that the EOM may help promote VBHC
                          for community oncology practices, we are concerned
                          that most community oncology practices participating
                          in the EOM must also participate in MIPS and carry the
                          reporting burdens of both programs.&nbsp; CMS should
                          better coordinate with CMMI to ensure that APMs
                          account for the burden of MIPS participation given the
                          likelihood they will not achieve QP status.&nbsp; In
                          addition, the EOM has additions to its practice
                          transformation requirements on top of OCM
                          requirements.&nbsp; For example, in the EOM, CMMI
                          requires the submission of electronic patient-reported
                          outcomes (“ePRO”) in the third year of the model, in
                          addition to other care transformation and reporting
                          requirements, which will continue to require practice
                          investments.&nbsp; However, EOM participants will
                          receive much lower per beneficiary per month payments
                          – $70 in the EOM versus $160 in the OCM – while being
                          exposed to greater financial risk and no longer
                          benefit from the five percent advanced AAPM bonus that
                          MACRA put in place through the performance year
                          2022.&nbsp; Additionally, as the EOM has two tracks
                          for risk arrangement, which differ by the level of
                          downside risk, practices that choose not to
                          participate in risk arrangement two (which includes
                          significantly greater downside risk and qualifies as
                          an AAPM would be required to participate in
                          MIPS.&nbsp; This would leave community oncology
                          practices with double the reporting requirements and
                          the increased possibility of paying recoupment to CMS
                          in the event of poor performance.
                          <strong>
                            &nbsp; We encourage Congress to work with CMS and
                            CMMI to ensure that new APMs sufficiently
                            incorporate MIPS participation and calibrate models
                            to allow community oncology practices to participate
                            without assuming untenable financial risk, which
                            could adversely impact patients.
                          </strong>
                        </p>
                        <p>
                          <strong>
                            <u>Recommendations to Improve MIPS and APMs</u>
                          </strong>
                        </p>
                        <p>
                          <strong>Short-Term Solutions</strong>
                        </p>
                        <p>
                          COA supports CMS’ work to speed up the promotion and
                          adoption of VBHC approaches through MIPS and
                          APMs.&nbsp;{" "}
                          <strong>
                            To encourage participation in APMs, CMS should
                            retain the five percent APM Incentive Payment after
                            the payment year 2024 and continue to allow those
                            practices to be exempt from MIPS reporting.&nbsp;{" "}
                          </strong>
                          CMS could also encourage participation in VBHC
                          initiatives by providing higher levels of technical
                          support for practices that wish to participate in APMs
                          as they transform their practices.&nbsp; Additionally,
                          to further improve MIPS, CMS should reduce its
                          complexity by changing the scoring system to reward
                          high-value providers rather than rewarding those who
                          can successfully navigate the program’s
                          complexity.&nbsp; CMS can also improve MIPS by taking
                          action to ensure that scoring does not inherently
                          disadvantage specialists due to the limitations of
                          applicable quality measures specialists have available
                          to report and the way in which the scoring system
                          works.
                          <strong>
                            &nbsp; When considering how to improve MIPS and
                            APMs, we encourage Congress to retain the five
                            percent APM Incentive Payment, provide additional
                            technical support for APM participants, and address
                            methodological issues with MIPS scoring.&nbsp; CMS
                            should also freeze the AAPM thresholds, which are
                            expected to increase, making it almost impossible
                            for community oncology practices to achieve without
                            participating in multiple Medicare and commercial
                            two-sided risk models.
                          </strong>
                        </p>
                        <p>
                          <strong>Long-Term Solutions </strong>
                        </p>
                        <p>
                          To incentivize comprehensive movement towards
                          value-based payments across payers, CMS should use
                          Medicare APMs as a catalyst to transform
                          practices.&nbsp; However, to encourage comprehensive
                          movement to VBHC, the Department of Health and Human
                          Services (“HHS”) should improve the Physician-Focused
                          Payment Model Technical Advisory Committee (“PTAC”),
                          which has become a complete joke.&nbsp; While PTAC was
                          originally intended to recommend physician-focused
                          payment models to HHS, models the committee has
                          reviewed have rarely been implemented.
                          <strong>
                            &nbsp; COA encourages Congress to work with HHS to
                            reform PTAC to better review and recommend payment
                            models that incorporate the provider perspective and
                            account for smaller practices, such as community
                            oncology practices.&nbsp; Empower PTAC and take the
                            politics out of it.
                          </strong>
                        </p>
                        <p>
                          Additionally, CMS could forgo the MPFS conversion
                          factor by using the American Medical Association’s
                          code valuation and apply a geographic adjustment
                          factor for the evaluation and management (“E&amp;M”)
                          and drug administration CPT codes or support
                          utilization and payment for chronic care management
                          (“CCM”) codes, similar to the proposed CY 2023
                          Optimizing Chronic Disease Management MVP.&nbsp;{" "}
                          <strong>
                            We encourage Congress to work with CMS to consider
                            these alternatives, which would ensure payment
                            parity for community oncology practices.
                          </strong>
                        </p>
                        <p>
                          COA is a pioneer in VBHC initiatives, as evidenced by
                          the Oncology Medical Home (“OMH”) program, which was
                          created by COA in 2012 and presented to PTAC.&nbsp;
                          OMH was piloted from 2013-2015 within the United OMH
                          project, which was implemented for Medicare patients
                          in seven oncology practices.&nbsp; Broadly, OMH
                          emphasizes care standards, validation of compliance of
                          those care standards, and allows teams to decide the
                          best way to accomplish those care standards.&nbsp;
                          Specifically, OMH seeks to prioritize
                          <a href="#_ftn8" name="_ftnref8">
                            [8]
                          </a>
                          :
                        </p>
                        <ul>
                          <li>
                            Patient engagement, which includes shared
                            decision-making;
                          </li>
                          <li>
                            Access to care in the right place at the right time;
                          </li>
                          <li>
                            Value-based treatment, including documentation of
                            the use of evidence and clinical pathways and
                            referral to clinical trials;
                          </li>
                          <li>
                            Access to equitable, comprehensive, and coordinated
                            team-based care;
                          </li>
                          <li>
                            Continuous practice quality improvement, including
                            the use of data and patient-reported outcomes;
                          </li>
                          <li>
                            Advance care planning and palliative and end-of-life
                            care, including discussions of goals; and
                          </li>
                          <li>
                            Adherence to chemotherapy standards based on Quality
                            Oncology Practice Initiative certification program
                            standards.
                          </li>
                        </ul>
                        <p>
                          COA looks forward to the new OMH certification pilot,
                          which is a partnership between COA and the American
                          Society of Clinical Oncology (“ASCO”).&nbsp; It
                          includes 12 cancer care teams of different types,
                          which adhere to the same standards.&nbsp; COA and ASCO
                          intend to launch the program nationally on July 1,
                          2023.
                          <strong>
                            &nbsp; As Congress and CMS consider how to improve
                            MACRA and promote value-based care, they should
                            incorporate features of the OMH into existing and
                            future value-based payment models in oncology.
                          </strong>
                        </p>
                        <p>
                          <strong>
                            <u>
                              Unrealistic and Harmful Medicare Reimbursement
                            </u>
                          </strong>
                        </p>
                        <p>
                          Despite skyrocketing inflation, CMS has not addressed
                          rising practice expenses fueled by rampant, historic
                          inflation in recent rulemaking.&nbsp; In fact, the
                          Medicare reimbursement cuts that CMS has proposed for
                          2023 are not only unrealistic but also harmful.&nbsp;
                          CMS appears to be intent on driving independent
                          medical practice into more expensive hospitals,
                          increasing costs for both Medicare and its
                          beneficiaries.&nbsp; For example, COA’s model projects
                          that total Medicare payment reductions for community
                          oncology practices will be 5.63 percent in 2023,
                          consisting of an 8.60 percent decrease for infusion
                          services, a 5.43 percent decrease for imaging
                          services, and a 3.88 percent decrease for E&amp;M
                          services.
                          <a href="#_ftn9" name="_ftnref9">
                            [9]
                          </a>
                          &nbsp;{" "}
                          <strong>
                            We implore Congress to immediately pass legislation
                            to ensure that community oncology practices and
                            other independent medical specialty practices are
                            able to provide VBHC to their patients, in light of
                            runaway inflation, unforeseen COVID-19 pressures,
                            and other financial and administrative barriers.
                          </strong>
                        </p>
                        <p>
                          <strong>
                            <u>Conclusion</u>
                          </strong>
                        </p>
                        <p>
                          COA appreciates the opportunity to respond to the
                          RFI.&nbsp; It is impossible in one document to
                          literally fix the convoluted, byzantine Medicare
                          reimbursement system for physicians.&nbsp; In this RFI
                          response, we have attempted to suggest specific
                          fixes.&nbsp; We look forward to sitting down and
                          working with Congress to further patient-centered
                          policies and improve both the quality and cost of
                          medical care while continuing to provide quality care,
                          especially during challenging times caused by the
                          ongoing COVID-19 public health emergency and runaway
                          inflation.
                        </p>
                        <p>
                          <strong>
                            We cannot emphasize enough that if Congress is truly
                            interested in maintaining a healthy national network
                            of independent community oncology practices and
                            other independent specialty medical practices, it
                            must take action to improve MACRA and further
                            promote value-based care initiatives.
                          </strong>
                        </p>
                        <p>
                          We are available to discuss any of our concerns or
                          recommendations regarding the comments provided in
                          this letter and thank you for your consideration.
                        </p>
                        <p>
                          Sincerely,
                          <a href="#_ftnref1" name="_ftn1"></a>
                        </p>
                        <p>
                          <a href="#_ftnref1" name="_ftn1"></a>
                        </p>
                        <p>
                          <a href="#_ftnref1" name="_ftn1"></a>
                        </p>
                      </div>
                      <img
                        loading="lazy"
                        src="https://communityoncology.org/wp-content/uploads/2022/05/Patel-Signature.png"
                        width="109"
                        height="30"
                      ></img>
                      <p className="mb-4">
                        Kashyap Patel, MD
                        <br />
                        President
                      </p>
                      <img
                        loading="lazy"
                        src="https://communityoncology.org/wp-content/uploads/2022/05/Okon-Signature.png"
                        width="109"
                        height=""
                      ></img>
                      <p>
                        Ted Okon
                        <br />
                        Executive Director
                      </p>
                      <div>
                        <p>
                          <a href="#_ftnref1" name="_ftn1">
                            [1]
                          </a>{" "}
                          <a href="https://communityoncology.org/reports-and-publications/studies-and-reports/2020-payment-reform-model-brief/">
                            https://communityoncology.org/reports-and-publications/studies-and-reports/2020-payment-reform-model-brief/
                          </a>
                        </p>
                        <p>
                          <a href="#_ftnref2" name="_ftn2">
                            [2]
                          </a>{" "}
                          <a href="https://communityoncology.org/featured/examining-hospital-price-transparency-drug-profits-and-the-340b-program-2022/">
                            https://communityoncology.org/featured/examining-hospital-price-transparency-drug-profits-and-the-340b-program-2022/
                          </a>
                        </p>
                        <p>
                          <a href="#_ftnref3" name="_ftn3">
                            [3]
                          </a>{" "}
                          RevCycle Intelligence. Cancer Care Costs 60% Higher at
                          Hospitals Vs Independent Orgs. 2017. Available{" "}
                          <a
                            href="https://revcycleintelligence.com/news/cancer-care-costs-60-higher-at-hospitals-vs-independent-orgs"
                            target="_self"
                            rel="noopener"
                          >
                            Here
                          </a>
                          .
                        </p>
                        <p>
                          <a href="#_ftnref4" name="_ftn4">
                            [4]
                          </a>{" "}
                          Avalere Analysis of OncoSpark Data. 2022.
                        </p>
                        <p>
                          <a href="#_ftnref5" name="_ftn5">
                            [5]
                          </a>{" "}
                          Modern Healthcare. Potential MACRA Byproduct:
                          Physician Consolidation. 2016. Available
                          <a
                            href="https://www.modernhealthcare.com/article/20160630/NEWS/160639995/potential-macra-byproduct-physician-consolidation"
                            target="_self"
                            rel="noopener"
                          >
                            {" "}
                            Here
                          </a>
                          .
                        </p>
                        <p>
                          <a href="#_ftnref6" name="_ftn6">
                            [6]
                          </a>{" "}
                          COA. 2020 Community Oncology Alliance Practice Impact
                          Report. 2020. Available{" "}
                          <a href="https://communityoncology.org/reports-and-publications/studies-and-reports/2020-community-oncology-alliance-practice-impact-report/">
                            Here
                          </a>
                          .
                        </p>
                        <p>
                          <a href="#_ftnref7" name="_ftn7">
                            [7]
                          </a>{" "}
                          COA. Community Oncology Alliance Statement on the
                          Medicare Drug Pricing Negotiation in Reconciliation
                          Bill. 2022. Available{" "}
                          <a href="https://communityoncology.org/news/press-releases-media-statements/community-oncology-alliance-statement-on-medicare-drug-pricing-reconciliation-bill/">
                            Here
                          </a>
                          .
                        </p>
                        <p>
                          <a href="#_ftnref8" name="_ftn8">
                            [8]
                          </a>{" "}
                          Oncology Medical Home. New ASCO-COA Oncology Medical
                          Home Standards Set High Bar, Offers Roadmap for
                          Comprehensive High-quality, Patient-Centered Cancer
                          Care Delivery. 2021. Available{" "}
                          <a
                            href="https://medicalhomeoncology.org/key-domains/new-asco-coa-oncology-medical-home-standards/"
                            target="_self"
                            rel="noopener"
                          >
                            Here
                          </a>
                          .
                        </p>
                        <p>
                          <a href="#_ftnref9" name="_ftn9">
                            [9]
                          </a>{" "}
                          COA. COA Submits Formal Comments on 2023 Physician Fee
                          Schedule. 2022. Available{" "}
                          <a href="https://communityoncology.org/reports-and-publications/comment-letters/coa-formal-comments-on-2023-physician-fee-schedule/">
                            Here
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                    {/* ) : ( */}
                    <div id="noAuthCan">
                      <p>...</p>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </article>
            </Col>

            <Col lg="3" md="4">
              <div className="post-sidebar">
                <a href="#" target="blank" className="download-button">
                  <i className="fa-solid fa-file-pdf"></i>
                  <span className="button-text d-flex align-items-center justify-content-center">
                    <FileText size={16} className="mr-25" /> DOWNLOAD (PDF)
                  </span>
                </a>

                <div className="post-img">
                  <img
                    src="https://communityoncology.org/wp-content/uploads/2020/04/IMG_2175.jpg"
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div className="related-posts-wraper">
                  <h4>More Related Content</h4>
                  <div className="related-posts">
                    <h2>Related Posts</h2>
                    <ul className="related-posts-list">
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          Letter to Defense Health Agency on TRICARE PBM
                          Concerns
                        </a>
                      </li>
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          Senate Letter to FTC on Status of PBM Investigation
                        </a>
                      </li>

                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          340B Reimbursement Proposal Could Reduce Payments for
                          80% of Hospitals
                        </a>
                      </li>
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          COA Formal Comments to CMS on Enhancing Oncology Model
                          Concerns
                        </a>
                      </li>
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          Congressional Letter to UnitedHealthcare on CINV Step
                          Therapy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SinglePostWrapper>
      <PostTeaser />
    </>
  );
};

export default SinglePost;
