import React, { useState, useEffect } from "react";
import {Button,Card,Col,Container,Input,InputGroup,InputGroupText,Row,Spinner} from "reactstrap";
import HorizontalArticle from "../../components/horizontal-article/HorizontalArticle";

// images
import articlePlaceholder from "../../assets/images/comment-letters-placeholder.jpg";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";
import {getPostBySlug} from "../../Store/Action/PostAction.js"
import { useDispatch, useSelector } from "react-redux";

const Post = () => {
  const { category, slug }            = useParams();
  const [posts, setPosts]             = useState([]);
  const dispatch                      = useDispatch();
  const store                         = useSelector(state => state?.posts?.allPosts);

  // Search and pagination
  const [sort, setSort]               = useState({ value: "Date Desc", label: "By Date Descending" });
  const [search, setSearch]           = useState('');
  const options = [{ value: "Date Desc",  label: "By Date Descending" },
                    { value: "Date Asc",  label: "By Date Ascending" },
                    { value: "Title Asc", label: "By Title A-Z" },
                   { value: "Title Desc",label: "By Title Z-A" }];                
  const [data, setData] = useState({
                                      category   : category,
                                      slug       : slug,
                                      initiative : [process.env.REACT_APP_INITIATIVE],
                                      page       : 1,
                                      search     : '',
                                      sort       : sort?.value,
                                      limit      : 10
                                    });
  useEffect(() => {
    searchPost(true)
    return dispatch({type:'GET_ALL_POSTS',data:[]})
  }, [data]);

// Concat old and new post
  useEffect(() => {
    if(store && store.posts && store.posts.length>0) { 
      let newpost = [...posts, ...store.posts]
      setPosts( prevState => (newpost) )
    }
  },[store])

  const loadMore = () => { 
    setData(prevData => ({...prevData, page: prevData.page+1}))
  }

  const handleSortOption = (sortValue) => {
    setSort(prevSort => sortValue);
    setPosts(prevState=>([]))
    setData(prevData => ({
      ...prevData,
      sort: sortValue?.value,
      page: 1
    }))
  }

  const handleFilter = () => {
    setData(prevData => ({
      ...prevData,
      search: search,
      page: 1
    }))
  }

  const searchPost = () => {
    if(data.page==1){ setPosts(prevState=>([]))}
    dispatch(getPostBySlug(data));
  }

  const slugToHeaderConvertion = (slug) => {
    var value = slug?.replace(/-/g, " ")
    switch (slug) {
      case 'em-coding-billing':
          value= "E&M, Coding And Billing";
        break;
      case 'education-publications':
          value= "Education & Publications";
        break;
    }
    return value;
  }

  return (
    <CategoryWrapper className="article-category py-6 pb-6">
      <Container>
        <Card className="inner-wrapper border-0 pt-5">
          <h1 className="text-center text-capitalize mb-3">
            {slugToHeaderConvertion(slug)}
          </h1>
          <SearchWrpper className="mb-5">
            <div className="search-box  ">
              <InputGroup>
                <Input
                  value={search}
                  onChange={(e) => { setSearch(e.target.value)}}
                  onKeyDown={(e) => { if(e.key === "Enter"){handleFilter()}}}
                  placeholder="Enter Resource Name"
                />
                <InputGroupText onClick={() => {handleFilter()}}>
                  SEARCH
                </InputGroupText>
              </InputGroup>
            </div>
          </SearchWrpper>

          <Row className="filter-wrapper">
            <Col className="auto"></Col>
            <Col xl="3" lg="4" md="4" sm="6">
              <Select
                options={options}
                className="search-filter"
                placeholder="Filter results"
                value={sort}
                onChange={(event) => {
                  handleSortOption(event);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    boxShadow: state.isFocused ? " unset" : "unset",
                    borderColor: state.isFocused
                      ? "var(--primary) !important"
                      : "silver",
                  }),
                  menuList: (styles) => ({
                    ...styles,
                    background: "#f3f3f3",
                    fontSize: "16px",
                  }),
                  option: (styles, { isFocused, isSelected }) => ({
                    ...styles,
                    background: isSelected ? "var(--primary)" : undefined,
                    zIndex: 1,
                  }),
                }}
              />
            </Col>
          </Row>
          <hr style={{ borderColor: "#173372" }} />
          <div className="articles mt-5">
            {posts && posts.length>0 && posts?.map((post, index) => {
              return (
                <HorizontalArticle
                key = {'horizon_artical_'+index}
                  // target={`/${category}/${slug}/${post?.slug}`}
                  target={ `${post?.slug}`}
                  image={
                    post?.feature_image
                      ? post?.feature_image
                      : articlePlaceholder
                  }
                  title={post.title}
                  //date={post.created_at}
                  date={
                    post.date
                      ? new Date(post.date).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                      : ""
                  }
                  categories_slugs={post?.categories_slugs}
                  excerpt={post.short_description}
                />
              );
            })}
            <div className="text-center">
              <Button onClick={()=>loadMore()} color="secondary" className="btn btn-danger" disabled={!(store.load_more)}>LOAD MORE</Button>
            </div>
          </div>
        </Card>
      </Container>
    </CategoryWrapper>
  );
};

export default Post;

export const CategoryWrapper = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 500px);

  .inner-wrapper {
    padding: 30px;
    padding-bottom: 0;
    border-radius: 30px;
  }
`;

export const SearchWrpper = styled.div`
  max-width: 698px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  .search-box {
    .input-group {
      input {
        border-width: 2px;
        height: 3.6rem;
        border-color: var(--primary);
      }

      .input-group-text {
        background: var(--primary);
        padding-left: 30px;
        padding-right: 30px;
        color: #fff;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: 600;
        border-width: 2px;
        border-left: unset;
        border-color: var(--primary);
        cursor: pointer;

        &:hover {
          background-color: var(--secondary);
          border-color: var(--secondary);
        }
      }
    }
  }
`;
