export const menuItems = [
  {
    title: "Education & Publications",
    link: "https://coaadvocacy.org/education-resources/",
    target: "_self",
    submenu: [
      {
        title: "Advocacy & Chapter Resources",
        link: "https://coaadvocacy.org/education-resources/",
        target: "_self",
      },

      {
        title: "Education Library",
        link: "https://coaadvocacy.org/education-resources/#education-library",
        target: "_self",
      },

      {
        title: "Sit In My Chair Visits",
        link: "https://coaadvocacy.org/education-resources/#_sit-in-my-chair",
        target: "_self",
      },

      {
        title: "Time to Screen Toolkit",
        link: "https://timetoscreen.org/advocacy-toolkit/",
        target: "_self",
      },
    ],
  },
  {
    title: "CPAN Chapters",
    link: "https://coaadvocacy.org/chapter/",
    target: "_self",
    submenu: [
      {
        title: "Find a CPAN Chapter",
        link: "https://coaadvocacy.org/chapter/#find-chapter",
        target: "_self",
      },
    ],
  },
  {
    title: "Meeting",
    link: "#",
    target: "_self",
    submenu: [
      {
        title: "Advocacy Chat Webinars & Recordings",
        link: "https://coaadvocacy.org/advocacy-chats/",
        target: "_self",
      },
      {
        title: "Annual CPAN Advocacy Summits",
        link: "https://coaadvocacy.org/advocacy-summits/",
        target: "_self",
      },
    ],
  },

  {
    title: "Members",
    link: "#",
    target: "_self",
    submenu: [
      {
        title: "Join CPAN, Become an Advocate",
        link: "https://coaadvocacy.org/become-a-cpan-advocate/",
        target: "_self",
      },
      {
        title: "CPAN Chapters",
        link: "https://coaadvocacy.org/chapter/",
        target: "_self",
      },
      {
        title: "Share Your Story",
        link: "https://coaadvocacy.org/share-your-story/",
        target: "_self",
      },
      {
        title: "Subscribe to CPAN Updates",
        link: "https://coaadvocacy.org/about/#_subscribe",
        target: "_self",
      },
    ],
  },

  {
    title: "News & Updates",
    link: "https://coaadvocacy.org/news/",
    target: "_self",
    submenu: [
      {
        title: "Latest Updates",
        link: "https://coaadvocacy.org/news/#latest-updates",
        target: "_self",
      },
      {
        title: "Year in Review",
        link: "https://coaadvocacy.org/news/#year-review",
        target: "_self",
      },
      {
        title: "CPAN News Bulletins",
        link: "https://coaadvocacy.org/news/#news-bulletins",
        target: "_self",
      },
      {
        title: "COA News & Updates",
        link: "https://communityoncology.org/category/news/",
        target: "_self",
      },
    ],
  },
  {
    title: "About",
    link: "https://coaadvocacy.org/about/",
    target: "_self",
    submenu: [
      {
        title: "About CPAN",
        link: "https://coaadvocacy.org/about/#what-we-do",
        target: "_self",
      },
      {
        title: "Leadership & Advisory Board",
        link: "https://coaadvocacy.org/about/#leadership",
        target: "_self",
      },
      {
        title: "What is Community Oncology?",
        link: "/about/what-is-community-oncology",
        target: "_self",
      },
      {
        title: "Our Advocates & Their Stories",
        link: "https://coaadvocacy.org/stories/",
        target: "_self",
      },

      {
        title: "Contact Us",
        link: "https://coaadvocacy.org/contact/",
        target: "_self",
      },
    ],
  },
];
