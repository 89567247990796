import { combineReducers } from 'redux'
import  PostReducer  from "./PostReducer"
import  users  from "../../../src/views/user/store/reducer/index.js"

// Combine Reducers
const RootReducer = combineReducers({
    posts: PostReducer,
    users: users
});

export default RootReducer;