import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";

export const ExternalApiComponent = () => {
  const { apiOrigin = "http://localhost:3001", audience } = getConfig();
  const domain = "login.mycoa.io";
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const {
    loginWithRedirect,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);


  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated)
console.log("user", user)
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
          //audience: `https://${domain}/api/v2/`,
          //scope: "read:current_user_metadata",
        });
        console.info("accessToken", accessToken1)
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        console.info("userDetailsByIdUrl", userDetailsByIdUrl)
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });
        console.info("metadataResponse", metadataResponse)
        const { user_metadata } = await metadataResponse.json();
        console.info("user_metadata", user_metadata)
        setUserMetadata(user_metadata);
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata();
   // getUser(user.sub)
  }, [getAccessTokenSilently, user?.sub]);
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  // const getUser = async (id) => {
  //   try {
  //     console.info("sdfdsf")
  //     const accessToken = await getAccessTokenSilently({
  //       audience: `https://express-api/`,
  //     });
  //     const response = await axios.get(`https://can-mycoa.herokuapp.com:3001/user/${id}`, {
  //       headers: {
  //         authorization: `Bearer ${accessToken}`,
  //       },
  //     });
  //     console.info("getUser", response.data);
  //     setUserMetadata(response.data.user_metadata);
  //   } catch (error) {
  //     console.info("error", error);
  //   }
  // };

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      const returnPath = window.location.pathname;
      await loginWithRedirect({ appState: { returnTo: `${returnPath}` } });
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/api/external`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  const [loader, setLoader] = useState(true)
  setTimeout(hideElement, 1500) //milliseconds until timeout//
  function hideElement() {
    setLoader(false)
  }

  return (
    userMetadata ? ( 
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}
{userMetadata && userMetadata.CPAN=="CPAN" ? ( 
        <div>
      <h1 className='pb-3'>CPAN Administrator’ Network</h1>
      <p>
      Maecenas sodales metus scelerisque ante tristique faucibus. Etiam molestie pretium enim, a tristique sem pellentesque vitae. Curabitur semper, ante ut ultricies suscipit, urna ipsum facilisis turpis, vel imperdiet eros dui sit amet mi. Nullam sit amet dolor et est convallis convallis eu non ipsum. Aenean pretium purus sed nisl congue eleifend. Quisque vel lacinia odio. In libero metus, aliquam eu accumsan vitae, ornare ut sapien. Integer placerat nisl vitae scelerisque accumsan. Maecenas ut augue consequat, pretium nunc eu, pharetra enim.
      </p>
      <p>
      Aliquam erat volutpat. Curabitur et bibendum elit, consectetur viverra leo. Quisque iaculis, quam eu malesuada volutpat, est tellus consectetur sapien, quis ullamcorper erat ante eu purus. Pellentesque condimentum pharetra consequat. In ac diam sit amet purus consequat varius. Donec pretium purus nisi, at cursus lacus rutrum venenatis. Sed malesuada massa elit, in congue quam pulvinar a. Nullam quam turpis, eleifend ut erat cursus, vehicula pretium diam. Vestibulum auctor dictum ultricies. Nunc nisl sem, laoreet volutpat purus non, ultricies fermentum nunc.
      </p>
      <p>
      Praesent fermentum dapibus ipsum. Pellentesque sit amet egestas urna. Quisque nec nisi feugiat, pretium purus ac, dictum eros. Phasellus eget condimentum est, quis dapibus libero.
      </p>
      <p>
      Curabitur et bibendum elit, consectetur viverra leo. Quisque iaculis, quam eu malesuada volutpat, est tellus consectetur sapien, quis ullamcorper erat ante eu purus. Pellentesque condimentum pharetra consequat. In ac diam sit amet purus consequat varius. Donec pretium purus nisi.
      </p> 
        </div>
     
        ) : (
          
          <Alert color="danger">
            You're not having access to this resource.
        </Alert>
          ) }
        
      </div>   
      
      
      
    </>
    ) : ( 
      <div>
      {loader ? ("Loading.... ") : ("You're not having access to this resource.") }
      </div>
    )
     
     
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});
